import React from 'react'
import OnVisible from 'react-on-visible'
import styled from 'styled-components'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/hero'
import Boilerplate from '../../components/boilerplate'

import hexagonIllustration from '../../images/illustration-hexagon.svg'
import qualityIllustration from '../../images/illustration-quality.svg'
import imagePolygonCopacking from '../../images/image-polygon-copacking.png'
import imagePolygonLogistic from '../../images/image-polygon-logistic.png'
import imagePolygonPos from '../../images/image-polygon-pos.png'

const LinkButton = styled.a`
  background-color: ${props => props.theme.colorPrimary};
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: 0.5s;

  &:hover {
    background-color: ${props => props.theme.colorGrayDark};
  }
`

const IndexPage = () => (
  <Layout lang="en">
    <SEO
      title="GDP-compliant co-packing of POS displays"
      keywords={['creopharm', 'pharma', 'co-packing']}
      description="The professional and careful stocking of point-of-sale displays as well as an agile and precise roll-out phase are two of the strengths that we provide to the pharmaceutical sector."
    />

    <Hero lang="en" />

    <section id="about">
      <OnVisible
        percent={20}
        className="container medium-padding animated fromBottom"
      >
        <div className="column">
          <h2 style={{ marginTop: 0 }}>
            Expert, reliable co-packing of your medicinal products,
            pharmaceuticals and cosmetics
          </h2>
          <p>
            Each individual step in the process is based on the provisions of
            the German Medicinal Products Act (“AMG”) and the corresponding
            European GDP guidelines. Having obtained a wholesale permit under
            the terms of Section 52a AMG, we know exactly what it comes down to
            when handling pharmaceutical items. Our newly built facilities
            enable us to guarantee the proper co-packing of medicinal products,
            pharmaceuticals and cosmetics. This packaging qualification and
            transport validation, both of which incorporate temperature mapping
            as well as other statutory requirements, likewise form part of our
            service.
          </p>
          {/* <p>
            <LinkButton
              href="https://www.google.de"
              target="_blank"
              rel="noopener"
            >
              Großhandelserlaubnis gemäß §52a AMG / GDP
            </LinkButton>
          </p> */}
        </div>
        <div className="column">
          <img src={hexagonIllustration} alt="Illustration Hexagon" />
        </div>
      </OnVisible>
    </section>

    <section id="services">
      <div id="copacking" className="background-gray">
        <OnVisible
          percent={20}
          className="container medium-padding animated fromBottom"
        >
          <div className="column image">
            <img src={imagePolygonCopacking} alt="Co-packing" />
          </div>
          <div className="column text">
            <h3 style={{ marginTop: 0 }}>
              Packaging and co-packing: careful handling of your branded
              products
            </h3>
            <p style={{ marginBottom: '0' }}>
              The professional and careful stocking of point-of-sale displays as
              well as an agile and precise roll-out phase are two of the
              strengths that we provide to the pharmaceutical sector. In
              completing this, we are careful to ensure detailed compliance with
              statutory documentation requirements. Ongoing monitoring of our
              operations and our stringent quality management set us apart from
              the crowd and ensure that we never lose sight of our customers'
              demands or the statutory provisions.
            </p>
          </div>
        </OnVisible>
      </div>
      <div id="logistic">
        <OnVisible
          percent={20}
          className="container medium-padding animated fromBottom"
        >
          <div className="column image">
            <img src={imagePolygonLogistic} alt="Logistic" />
          </div>
          <div className="column text">
            <h3 style={{ marginTop: 0 }}>
              Storage and logistics: expertly and perfectly managed right up to
              the point of sale
            </h3>
            <p style={{ marginBottom: '0' }}>
              Our state-of-the-art facilities satisfy the highest quality
              standards. Care, cleanliness, pest control procedures and
              temperature mapping are second nature to us. To assist you
              throughout the production chain, we take care of the co-packing,
              packaging, licenced storage and professional logistics on your
              behalf. Whether domestic or international shipments, your products
              are delivered to your central warehouse or directly to pharmacies,
              drug stores or retailers in perfect condition.
            </p>
          </div>
        </OnVisible>
      </div>
      <div id="pos" className="background-gray">
        <OnVisible
          percent={20}
          className="container medium-padding animated fromBottom"
        >
          <div className="column image">
            <img src={imagePolygonPos} alt="POS" />
          </div>
          <div className="column text">
            <h3 style={{ marginTop: 0 }}>
              One-stop-supplier: creativity and co-packing from a single source
            </h3>
            <p>
              Our service goes that extra mile. As a subsidiary of Creodis GmbH,
              we can count on more than ten years’ experience of creating
              ultramodern POS displays. We listen carefully and fully understand
              what you and your customers demand of the product. This allows us
              to develop exceptional point-of-sale solutions which, in a split
              second, convey the added value of your products and thus are the
              key to establishing trust between the end customer and your
              product.
            </p>
            <p>
              From the very creation of the displays – which also entails
              development and prototyping – to their actual production as well
              as co-packing, warehousing and logistics operations, every single
              step can be found all under one roof. This allows us to optimise
              the value chain and minimise the amount of transport involved. We
              are swift and flexible in our actions and deeds and always have
              one eye on being as cost-effective as possible.
            </p>
            <p>
              Your needs and wishes take priority for us – which is why a
              permanent contact person is on hand throughout the project to
              ensure smooth progress and efficient coordination.
            </p>
            <p style={{ marginBottom: '0' }}>
              <LinkButton
                href="https://www.creodis.de"
                target="_blank"
                rel="noopener"
              >
                More about Creodis
              </LinkButton>
            </p>
          </div>
        </OnVisible>
      </div>
    </section>

    <section id="quality">
      <OnVisible
        percent={20}
        className="container medium-padding animated fromBottom"
      >
        <div className="column">
          <img src={qualityIllustration} alt="Illustration Quality" />
        </div>
        <div className="column">
          <h3 style={{ marginTop: 0 }}>Our commitment to quality</h3>
          <p style={{ marginBottom: 0 }}>
            Medicinal products and pharmaceuticals need to instil trust and
            lastingly assure end consumers. With this in mind, we at the Creodis
            Group focus on each individual customer, taking their demands and
            the statutory requirements into consideration. This allows us not
            only to create authentic POS displays for the pharmaceutical sector
            but also to co-pack them in compliance with the relevant
            legislation. To this end, we work in close partnership with our
            customers and to the highest self-imposed standards to create
            bespoke complete solutions that both you and ultimately your end
            customers will find convincing.
          </p>
        </div>
      </OnVisible>
    </section>

    <section id="career" className="background-gray">
      <OnVisible
        percent={20}
        className="container medium-padding animated fromBottom"
        style={{ textAlign: 'center', maxWidth: '760px' }}
      >
        <h3 style={{ marginTop: 0 }}>Join our team!</h3>
        <p>
          We have aroused your interest? We therefore look forward to receiving
          your application, both for the commercial and industrial sector.
        </p>
        <p>Please contact:</p>
        <p style={{ marginBottom: 0 }}>
          Andrea Gustenberg
          <br />
          Mail:{' '}
          <a href="mailto:a.gustenberg@creodis.de">a.gustenberg@creodis.de</a>
        </p>
      </OnVisible>
    </section>

    <Boilerplate lang="en" />
  </Layout>
)

export default IndexPage
